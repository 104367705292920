/* login.css */
/* .btn-xl{
    line-height: 2;
} */
/* @media (max-width: 767px){
    #pinLoginRoot {
        max-width: 20rem !important;
    }
}
@media (min-width: 768px){
    #pinLoginRoot {
        max-width: 30rem !important;
    }
} */

/* #pinLoginRoot{
    width: 30vw;
    height:40vh;
} */

.toLoginModuleButton {
    text-align: center;
    padding-top: 250px;
    max-width: 100%;
    opacity: 100;
}
.container-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}
.login-clean {
    height: 311px;
    width: auto;
    border: 1.5px solid #ff8217;
    background-color: #222222;
}
.btn-light {
    max-width: 75%;
    height: 50px;
    opacity: 1;
    border: solid 2px;
    border-color: #ff8217;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    background-color: #222222;
    border-radius: 40px;
    margin: 5px;
    margin-top: 30px;
}
.btn-light:hover {
    background-color: #ff8217;
    border-color: #ff8217;
    color: #ffffff;
}
.logInBackground {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.bck-common-topbar {
    background-color: #222222;
    height: 70px;
}
.footerBottom {
    width: 100%;
    padding-top: 0;
    background-color: #222222;
    height: 130px;
    display: block;
}
.container-fluid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.text-center {
    text-align: center;
    font-family: Archivo, sans-serif;
    color: #ffffff;
    vertical-align: middle;
}
.copyright {
    font-size: 13px;
    color: #858796;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif;
}
.justify-content-center {
    justify-content: center !important;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
}
.logInContainerHeading {
    color: white;
    /* font-family: Miller Ban WGL ROM, georgia, serif ; */
    font-family: Archivo, sans-serif;
    font-size: 54px;
    padding: 40px;
    padding-top: 60px;
    font-weight: 500;
    max-width: 290px;
}
.textPart {
    width: auto;
    height: 308px;
    float: left;
}
.loginpic {
    width: 387px;
    height: 307.5px;
}

@media (max-width: 1000px) {
    .login-clean img {
        display: none !important;
    }
}
