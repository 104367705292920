/* index.css */
html,
body {
    height: 100%;
}
body {
    background-color: #222222;
    color: #e0e0e0;
}
#root {
    margin: auto;
    height: 100%;
}

.border-orange {
    border-color: orange !important;
}
.popUp {
    overflow: auto;
    background-color: #222222 !important;
    border: solid 1px;
    border-radius: 0.5rem;
    min-width: 30rem;
    /* min-height: 90vh; */
    padding: 0;
    padding-top: 1rem;
}
.popUp.right {
    margin-right: 2vw !important;
    min-width: 40rem;
}
.popUp.full-screen {
    max-width: 100vw !important;
}
@media (max-width: 1024px) {
    .popUp.full-screen {
        max-width: 100vw !important;
        width: 100vw;
    }
}

.popUp.nonCont {
    width: 100%;
    min-width: 100%;
}

/*kasutusel seadistuse alustamise nupu juures */
.vertical-center {
    top: 50%;
    position: absolute;
    transform: translate(0, -50%);
}
.horizontal-center {
    left: 50%;
    position: absolute;
    transform: translate(50%, 0);
}
/*qualitychecklist*/
.ourModal {
    padding-top: 10px;
    margin-right: 15px;
    z-index: 4;
    border-radius: 0.5rem;
    background-color: #222222 !important;
    position: relative;
    -webkit-box-shadow: inset 0px 0px 0px 1px;
    -moz-box-shadow: inset 0px 0px 0px 1px;
    box-shadow: inset 0px 0px 0px 1px;
}
.overlayBG {
    background-color: rgba(160, 160, 160, 0.3);
}

.centerModal.w-30 {
    width: 30vw;
    max-width: 400px;
    min-width: 200px;
}

.dropdown {
    margin-right: 15px;
}

/* select#changeLang {
    background-color: #444444 !important;
    border: none;
    color: white !important;
} */

.dropdown-toggle {
    width: 44px;
    height: 44px;
    padding: 0px;
    background-color: #444444;
    border: none;
    box-shadow: none !important;
}

.dropdown-toggle:after {
    content: none;
}

.dropdown-menu {
    background-color: #444444;
    border-color: rgba(160, 160, 160, 0.8);
    min-width: 4rem;
}

.dropdown-item {
    font-size: 26px;
    color: white;
}

.dropdown-item:hover {
    background-color: #438bf7;
}

/* input group item - start */
.list-group-item-mes {
    color: inherit;
    background-color: inherit;
    border-color: rgba(160, 160, 160, 0.8);
}
/* input group item - start */

/* modal */
.react-responsive-modal-modal {
    background-color: #222222 !important;
    padding: 0 !important;
    max-width: 1023px !important;
}

.react-responsive-modal-modal.right {
    margin-right: 2vw !important;
    min-width: 40rem;
}
.react-responsive-modal-modal.full-screen {
    max-width: 100vw !important;
}
@media (max-width: 1024px) {
    .react-responsive-modal-modal.full-screen {
        max-width: 100vw !important;
        width: 100vw;
    }
}
.react-responsive-modal-modal.popUp {
    padding: 1.2rem !important;
}
.react-responsive-modal-modal.nonCont {
    width: 100%;
    min-width: 100%;
}
