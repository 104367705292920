

.loader-container {
    display: flex;
    position: relative;
    top: 50%;   
    justify-content: center;
}

.loader {
    width: 70px;
    height: 50px;
    position: relative;
    overflow: hidden;   
}

.loader span {
    position: absolute;
    width: 9px;
    height: 9px;
    background-color:#FF8217;
    border-radius: 50%;
    top: 30px;
    left: 10px;
    animation: 1s linear infinite;
    transform-origin: 50% -48px;
    animation-name: run;
}

.loader span:nth-child(2) {animation-delay: 0.4s;}
.loader span:nth-child(3) {animation-delay: 0.2s;}

@keyframes run {
    100%  {left: 100px;}
}